import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import { Input } from '../form-control/Input';
import { FileInfo } from './FileInfo';
import SingleFileInfo from './SingleFileInfo';
import UploadArea from './UploadArea';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';

type SingleFileModalProps = {
  open: boolean;
  file?: FileInfo | null;
  canNameFile?: boolean;
  onCancel: () => void;
  onSave: (file: FileInfo) => void;
};

const SingleFileModal: FC<SingleFileModalProps> = (props) => {
  const { t } = useTranslation('common');
  const { open, file, onCancel, onSave, canNameFile = true } = props;
  const [name, setName] = useState<string>(file?.name || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [internalFile, setInternalFile] = useState<FileInfo | null>(null);
  const [progress, setProgress] = useState<number | undefined>();
  const [error, setError] = useState<string | undefined>();

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  useEffect(() => {
    setInternalFile(file || null);
    setName(file?.name || '');
    setIsEditing(!!file);
  }, [file, open]);

  const onUpload = (files: FileInfo[]): void => {
    const file = files[0];
    setInternalFile(file);
    setName(file?.name);
    setError(undefined);

    if (!file) {
      return;
    }

    if (file.size >= FileService.MAX_SIZE) {
      setError(t('file-upload.errors.file-to-large'));
      return;
    }

    setProgress(0);
    FileService.uploadFile(file.file, (perc) => {
      setProgress(perc);
    })
      .then((res) => {
        setInternalFile((file) => file && { ...file, id: res.data[0].id, mimeType: res.data[0].contentType });
        setProgress(undefined);
      })
      .catch((err) => {
        setError(err.meta.message);
      });
  };

  const onCancelClick = () => {
    setInternalFile(null);
    setName('');
    setError(undefined);
    onCancel && onCancel();
  };

  const onSaveClicked = (): void => {
    setInternalFile(null);
    setName('');
    if (internalFile) {
      internalFile.name = name;
      onSave(internalFile);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <ModalContext.Provider value={{ open: open, onClose: onCancel, modalWidth: 'w-2/5' }}>
      <StandardModal
        title={!isEditing ? t('single-file-upload-modal.heading.upload') : t('single-file-upload-modal.heading.edit')}
        cancelButtonTitle={t('file-upload.buttons.cancel')}
        confirmButtonTitle={t('file-upload.buttons.save')}
        onCancelClick={onCancelClick}
        onConfirmClick={internalFile ? onSaveClicked : undefined}
        confirmDisabled={!!error || progress !== undefined}
      >
        <div className="pb-7">
          {!internalFile && <UploadArea onUpload={onUpload} />}
          {!!internalFile && <SingleFileInfo file={internalFile} progress={progress} error={error} onCancel={() => onUpload([])} />}
        </div>
        {canNameFile && (
          <>
            <div>{t('file-viewer.name-input.label')}</div>
            <Input value={name} onChange={onNameChanged} placeholder={t('file-viewer.name-input.placeholder')} data-cy="file-name-input" />
          </>
        )}
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default SingleFileModal;
