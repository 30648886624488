import { FC } from 'react';
import { FileUtils } from '../../../utils/FileUtils';
import FileIcon from '../icon/FileIcon';
import XIcon from '../icon/XIcon';
import ProgressBar from '../ProgressBar';
import Tooltip from '../Tooltip';
import { FileInfo } from './FileInfo';

type SingleFileInfoProps = {
  file: FileInfo;
  onCancel?: () => void;
  error?: string;
  progress?: number;
};

const SingleFileInfo: FC<SingleFileInfoProps> = (props) => {
  const { file, onCancel: onFileCancel, error, progress } = props;

  return (
    <div className="border-gray-5 relative mx-auto w-64 rounded-md border-2 p-8 text-center" data-cy="file-info-block">
      {onFileCancel && <XIcon className="absolute right-0 top-0 m-1 h-4 w-4" onClick={onFileCancel} />}
      <div className="mx-4 my-6">
        <FileIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-3" />
      </div>
      <div className="text-color-1 font-medium" data-testid="single-file-info-name" data-cy="file-info-file-name">
        <Tooltip text={file.name} truncatedTextMode>
          {(tooltip) => (
            <div className="truncate" {...tooltip}>
              {file.name}
            </div>
          )}
        </Tooltip>
      </div>
      {error && <div className="text-color-4">{error}</div>}
      {!error && progress !== undefined && <ProgressBar progress={progress} />}
      {!error && progress === undefined && (
        <div data-testid="single-file-info-size" data-cy="file-info-file-size">
          {FileUtils.formatSize(file.size)}
        </div>
      )}
    </div>
  );
};

export default SingleFileInfo;
